import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo/seo"
import { PageParams } from '../../../types';

const AboutPage = (params: PageParams) => {
  const language = params.pageContext.language

  return (
    <>
      <SEO title="About" />
      <h1>About</h1>
      <p>Welcome to Aplinet</p>
      <Link to="/en">Go back to the homepage</Link>
    </>
  )
}

export default AboutPage
